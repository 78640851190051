.mainWrapper {
    width : 100%;
    height: 100%;
}

.preLoad {
    width          : 100%;
    height         : 100%;
    min-height     : 300px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.titleWrapper {
    h3 {
        text-transform: capitalize;
    }

    .backWrapper {
        display    : flex;
        align-items: center;
        cursor     : pointer;

        transition: .3s ease-in-out;

        p {
            margin     : 0;
            margin-left: 5px;
        }

        &:hover {
            color: #0d6efd;
        }
    }
}

.galleryWrapper {
    display              : grid;
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
    width                : 100%;
    margin-top           : 2rem;
    grid-gap             : 20px;
}

.oneFr {
    grid-template-columns: 1fr;
}

.halfSizeWrapper {
    width     : 70%;
    flex-basis: 70%;
}

.folderEditBtnWrapper {
    position        : absolute;
    top             : 10px;
    right           : 10px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 35px;
    height          : 35px;
    background-color: rgba(255, 255, 255, .8);
    border-radius   : 50%;
    transition      : .3s ease-in-out;
    cursor          : pointer;
    opacity         : 0;
    transform       : translateY(-10px);
    z-index         : 200;

    &:hover {
        background-color: rgba(255, 255, 255, 1);
    }
}

.relativeDisplay {
    position        : relative;
    width           : 100%;
    background-color: rgba(224, 224, 224, 0.7);
    border-radius   : 7px;
    transition      : .3s ease-in-out;
    color           : inherit;
    min-height      : 307px;

    &:hover {
        text-decoration : none;
        background-color: rgba(224, 224, 224, 1);
        color           : inherit;

        .folderEditBtnWrapper {
            opacity  : 1;
            transform: translateY(0);
        }
    }
}

.folderWrapper {
    width     : 100%;
    color     : inherit;
    min-height: 307px;
    cursor    : pointer;

    &:hover {
        text-decoration: none;
        color          : inherit;

    }
}



.imageWrapper {
    width : 100%;
    height: 222px;

    img {
        width        : 100%;
        height       : 100%;
        object-fit   : cover;
        cursor       : pointer;
        border-radius: 7px 7px 0 0;
    }
}

.folderDetailsWrapper {
    display    : flex;
    padding    : 2rem 1rem;
    align-items: flex-end;
    margin-top : -3rem;
}

.folderBg {
    background-color: rgba(224, 224, 224, 1);
    padding         : 1rem;
    border-radius   : 7px;
    margin-right    : 10px;

    i {
        font-size: 2rem;
    }
}

.folderTexts {

    h4,
    p {
        margin        : 0;
        text-transform: capitalize;
    }
}

.noDataWrapper {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    height         : 100%;
    width          : 100%;

    h2 {
        color      : #9c9fa6;
        font-weight: normal;
    }
}