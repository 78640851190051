.uploadTab {
	p,
	label {
		margin-bottom: 0.3rem;
	}
}

.titleWrapper {
	display: flex;
	align-items: center;
}

.buttonsWrapper {
	display: flex;
	align-items: center;
	// margin-left: auto;
}

.flexWrapper {
	display: flex;
	border: 1px solid #eee;
	padding: 20px 0;
	border-radius: 10px;
}

.selectedPhotoWrapper {
	img {
		width: 100%;
		max-height: 500px;
		object-fit: contain;
	}
}

.controlWrapper {
	margin-left: 2rem;
}

.extraInformationWrapper {
	display: flex;
	align-items: center;
	max-width: 500px;

	h3 {
		margin-bottom: 1rem;
	}

	.formGroup {
		margin-bottom: 1rem;
	}
}

.photoDetails {
	margin-bottom: 3rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #e6e6e6;
}

.uploadHereWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px dashed #b0b0b0;
	padding: 1rem 0;
	margin-top: 1rem;
}

.fileInput {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.fileLabel {
}

.dragAndDrop {
}

.accepted {
	font-size: 0.7rem;
	margin-top: 1rem;
}
