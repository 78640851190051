
.dialogClassName {
    max-width: fit-content;
}
.box{
    box-shadow: 0px 0px 17px -6px #989898;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 200px;
    height: 150px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
    box-shadow: 0px 0px 25px -6px #989898;

    }
    &.active{
        box-shadow: 0px 0px 3px 2px  #6b70f9;
        &:hover{
            box-shadow: 0px 0px 3px 2px  #6b70f9;
        }

    }
    .image{
        width: 100px;
    }
}