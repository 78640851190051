.box{
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    .item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        flex: 0 0 25%;
        cursor: pointer;
        padding: 10px;
   
        .icon{
            width: 80%;
        opacity: 0.2;

        }
        .label{
            font-size: 13px;
        }
        &.active{
            .icon{
                opacity: 1;

            }
        }
    }
}
