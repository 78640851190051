.designBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .designItem{
    flex: 0 0 20%;
    padding: 2px;
    border: 3px solid;
    border-color: transparent;
    cursor: pointer;
    img{
        width: 100%;
    }
    &.select{
    border-color: #20cf00;

    }
    }
}