.main-spinner-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: #eee;
	.donut {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 0.3rem solid rgba(#979fd0, 0.3);
		border-top-color: #979fd0;
		animation: 1.5s spin infinite linear;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

// forked from https://codepen.io/viktorstrate/pen/yoBRLy

$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient {
	background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
	background-size: 600px;
}

.line-skeleton {
	width: 100%;
	height: 100%;
	min-height: 10px;
	margin: 7px 0;
	border-radius: 7px;

	@include background-gradient;
	animation: shine-lines $animation-duration infinite ease-out;
	&.small {
		width: 50px;
		height: 10px;
	}
}

@keyframes shine-lines {
	0% {
		background-position: -100px;
	}
	40%,
	100% {
		background-position: 140px;
	}
}
